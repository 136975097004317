<template>
    <div>
        <div id="mapContainer" style="width: 100%; height: 100vh;"></div>


        <!-- 头部 -->
        <div class="head">
            <div style="position: absolute;top: 50%;left: 30px;color: #0a7988;font-size: 32px;display: flex;flex-direction: row;align-items: center;"
                @click="back">
                <span style="margin-top: -4px;">
                    ＜
                </span>
                <span v-if="lang == 'zh'" style="font-size: 22px;">返回</span>
                <span v-else style="font-size: 22px;">back</span>
            </div>
            <div style="font-size: 50px;text-align: center;color: #fff;">储能路灯分布地图界面</div>
            <div style="font-size: 23px;text-align: center;color: #fff;">Energy storage street light distribution map interface</div>
        </div>


        <div class="listBg">
            <div class="listBox">
                <div class="listData">

                    <div style="" v-for="(item, index) in listData" :key="index">
                        <div style="color: #66ffff;" @click="chilTypeChang(index)">
                        {{ (item.chilType ? '▲' : '▼') }} {{ item.name }}</div>

                        <div style="margin-left: 15px;margin-top: 10px;" v-if="item.chilType">
                            <div class="leftItem" v-for="(childItem, childIndex) in item.child" :key="childIndex"
                                @click="setCenter(childItem)">
                                {{ childItem.name }}
                            </div>
                        </div>
                    </div>


                    <!-- <div class="leftItem" v-for="(item, index) in listData" :key="index"
                        @click="setCenter(item.coordinate)">
                        {{ item.name }}
                    </div> -->
                </div>

                <!-- <div class="switchBtn">
                    <div class="next" @click="next('up')">
                        {{ lang == 'zh' ? '上一页' : 'up' }}
                    </div>

                    <ul class="pagination">
                        <li v-if="showStartDots" class="page-item_dots">...</li>

                        <li v-for="page in visiblePages()" :key="page" class="pageNumber"
                            :class="{ 'page-link': page === currentPage }" @click="goToPage(page)">
                            {{ page }}
                        </li>

                        <li v-if="showMiddleDots" class="page-item_dots">...</li>

                        <li v-if="showEndDots" class="page-item_dots">...</li>

                    </ul>


                    <div class="next" @click="next('down')">
                        {{ lang == 'zh' ? '下一页' : 'down' }}
                    </div>
                </div> -->

            </div>
        </div>

        <!-- 右边  :class="batteryData ? 'rgba1' : 'rgba0'" -->
        <div class="rigthBox">

            <div style="position: absolute;top: -80px;right: 0;width: 90%;display: flex;justify-content: space-evenly;" class="conItem" @click="costSavingsType = true" >
                <p>节省电费</p>
                <p>{{ saveElectricity }} (元)</p>
            </div>

            <div style="flex: 1;" class="right" v-if="batteryData">
                
                <div style="color: #66ffff;font-size: 28px;padding-left: 30px;margin-bottom: 10px;">
                    {{ batteryData.name }}
                </div>
                <div class="rightTop">
                    <div class="item">
                        <p>{{ Battery }}<span style="font-size: 16px;">%</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '剩余电量' : 'Battery' }}</p>
                    </div>

                    <div class="item">
                        <p>{{ batteryData.dischargePower ? batteryData.dischargePower : 0 }}<span
                                style="font-size: 16px;">w</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '功率' : 'power' }}</p>
                    </div>

                    <div class="item">
                        <p>{{ differentialPressure }}<span style="font-size: 16px;">v</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '压差' : 'differential pressure' }}</p>
                    </div>

                    <div class="item">
                        <p>{{ batteryData.sun_charge_Power_day ? batteryData.sun_charge_Power_day : 0 }}</p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '光伏日发电量(Wh)' : 'photovoltaics(Wh)' }}
                        </p>
                    </div>

                    <div class="item">
                        <p>{{ maxVoltage }}<span style="font-size: 16px;">v</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '单体最高电压' : 'ceiling voltage' }}</p>
                    </div>

                    <div class="item">
                        <p>{{ minVoltage }}<span style="font-size: 16px;">v</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '单体最低电压' : 'minimum voltage' }}</p>
                    </div>

                    <div class="item">
                        <p>{{ batteryData.chargePower ? batteryData.chargePower : 0 }}<span
                                style="font-size: 16px;">wh</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '充电电量' : 'Charging capacity' }}</p>
                    </div>

                    <div class="item">
                        <p>{{ batteryData.batEcele_day ? batteryData.batEcele_day : 0 }}<span
                                style="font-size: 16px;">wh</span></p>
                        <p style="font-size: 16px;color: #fff;">{{ lang == 'zh' ? '放电电量' : 'Discharge capacity' }}</p>
                    </div>
                </div>

                <div style="color: #66ffff;font-size: 18px;padding-left: 30px;margin-bottom: 10px;">
                    电池容量
                </div>
                <div class="rightButton">
                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">1</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_1_soc ? batteryData.bat_1_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_1_vol ? batteryData.bat_1_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">2</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_2_soc ? batteryData.bat_2_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_2_vol ? batteryData.bat_2_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">3</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_3_soc ? batteryData.bat_3_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_3_vol ? batteryData.bat_3_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">4</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_4_soc ? batteryData.bat_4_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_4_vol ? batteryData.bat_4_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">5</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_5_soc ? batteryData.bat_5_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_5_vol ? batteryData.bat_5_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">6</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_6_soc ? batteryData.bat_6_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_6_vol ? batteryData.bat_6_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">7</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_7_soc ? batteryData.bat_7_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_7_vol ? batteryData.bat_7_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <p style="font-size: 24px;color: #fff;">8</p>
                        <div class="battery">
                            <div class="batteryNum">
                                <div class="win"
                                    :style="{ 'width': batteryData.bat_8_soc ? batteryData.bat_8_soc + '%' : '0%' }"></div>
                                <p class="tet">{{ batteryData.bat_8_vol ? batteryData.bat_8_vol : 0 }}</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


        <!-- 中间 -->
        <div style="position: fixed;bottom: 30px;left: 50%;transform: translateX(-50%);display: flex;flex-direction: row;align-items: center;"
            :style="{ 'width': lang == 'zh' ? 70+'%' : 80+'%' }"   >
            <div class="conItem" style="display: flex;flex-direction: row;align-items: center;">
                <img style="width: 3rem;height: 2rem;margin-right: 10px;" src="@/assets/dianliang.png" alt="">
                <div style="text-align: center;display: flex;flex-direction: row;align-items: center;">
                    <p style="font-size: 16px;color: #fff;margin-right: 10px;">{{ lang == 'zh' ? '低谷照明 & 储存电量(年)' : 'Low valley lighting (year)' }}</p>
                    <p style="margin-top: 3px;">{{ batEcele_month ? batEcele_month : 0 }}<span style="font-size: 16px;">kw</span></p>
                </div>
            </div>

            <div class="conItem" style="display: flex;flex-direction: row;align-items: center;">
                <img style="width: 2rem;height: 2rem;margin-right: 10px;" src="@/assets/dianbiao.png" alt="">
                <div style="text-align: center;display: flex;flex-direction: row;align-items: center;">
                    <p style="font-size: 16px;color: #fff;margin-right: 10px;">{{ lang == 'zh' ? '峰时储能放电量(年)' : 'Energy storage discharge (year)' }}</p>
                    <p style="margin-top: 3px;">{{ totalEcele_month ? totalEcele_month : 0 }}<span style="font-size: 16px;">kw</span></p>
                </div>
            </div>

            <div class="conItem" style="display: flex;flex-direction: row;align-items: center;">
                <img style="width: 2rem;height: 2rem;margin-right: 10px;" src="@/assets/dianbiao.png" alt="">
                <div style="text-align: center;display: flex;flex-direction: row;align-items: center;">
                    <p style="font-size: 16px;color: #fff;margin-right: 10px;">{{ lang == 'zh' ? '光伏充电量(年）' : 'Photovoltaic (year)' }}</p>
                    <p style="margin-top: 3px;">{{ totalEcele_month ? totalEcele_month : 0 }}<span style="font-size: 16px;">kw</span></p>
                </div>
            </div>

            

            <div class="conItem" style="display: flex;flex-direction: row;align-items: center;">
                <img style="width: 3rem;height: 3rem;margin-right: 10px;" src="@/assets/Frame@2x.png" alt="">
                <div style="text-align: center;display: flex;flex-direction: row;align-items: center;">
                    <p style="font-size: 16px;color: #fff;margin-right: 10px;">{{ lang == 'zh' ? '二氧化碳减排' : 'carbon dioxide emission reduction' }}
                    </p>
                    <p style="margin-top: 3px;">{{ reduction() }}</p>
                </div>
            </div>

            <div class="conItem" style="display: flex;flex-direction: row;align-items: center;">
                <img style="width: 3rem;height: 3rem;margin-right: 10px;" src="@/assets/Frame@2x(1).png" alt="">
                <div style="text-align: center;display: flex;flex-direction: row;align-items: center;">
                    <p style="font-size: 16px;color: #fff;margin-right: 10px;">{{ lang == 'zh' ? '等效植树' : 'Equivalent tree planting' }}
                    </p>
                    <p style="margin-top: 3px;">{{ planting() }}<span style="font-size: 16px;">课</span></p>
                </div>
            </div>

            <!-- <div class="conItem" style="display: flex;flex-direction: row;align-items: center;" v-if="batteryData">
                <img style="width: 2rem;height: 2rem;margin-right: 10px;" src="@/assets/dianbiao.png" alt="">
                <div style="text-align: center;display: flex;flex-direction: row;align-items: center;">
                    <p style="font-size: 16px;color: #fff;margin-right: 10px;">
                        {{ lang == 'zh' ? '功率' : 'power' }}
                    </p>
                    <p style="margin-top: 3px;">{{ batteryData.dischargePower ? batteryData.dischargePower : 0 }}<span style="font-size: 16px;">w</span></p>
                </div>
            </div> -->
        </div>



        <!-- 计算节省电费 -->
        <div class="pup" v-if="costSavingsType">
            <div class="saveData">

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">传统灯具功率</span>
                    <el-input class="inputStyle" v-model="saveData.lightingFixtures" placeholder="请输入" type="text"></el-input>
                </div>
                
                <!-- <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">储能路灯功率</span>
                    <el-input class="inputStyle" v-model="saveData.lightPower" placeholder="请输入" type="text"></el-input>
                </div> -->

                <!-- <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">标称功率</span>
                    <el-input class="inputStyle" v-model="saveData.power" placeholder="请输入" type="text"></el-input>
                </div> -->

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">高峰时间</span>
                    <div class="inputStyle" style="display: flex">
                        <el-time-picker
                            v-model="saveData.peakstartTimeSlot[0]"
                            :picker-options="{minTime:'00:00:00',maxTime: '23:59:59'}"
                            placeholder="选择时间范围">
                        </el-time-picker>
                        <div style="margin: 0px 15px">-</div>
                        <el-time-picker
                            v-model="saveData.peakstartTimeSlot[1]"
                            :picker-options="{minTime:'00:00:00',maxTime: '23:59:59'}"
                            placeholder="选择时间范围">
                        </el-time-picker>
                    </div>
                </div>
                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">高峰电价</span>
                    <el-input class="inputStyle" v-model="saveData.peakPrice" placeholder="请输入" type="text"></el-input>
                </div>

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">平谷时间</span>
                    <div class="inputStyle" style="display: flex">
                        <el-time-picker
                            v-model="saveData.pinggustartTimeSlot[0]"
                            :picker-options="{minTime:'00:00:00',maxTime: '23:59:59'}"
                            placeholder="选择时间范围">
                        </el-time-picker>
                        <div style="margin: 0px 15px">-</div>
                        <el-time-picker
                            v-model="saveData.pinggustartTimeSlot[1]"
                            :picker-options="{minTime:'00:00:00',maxTime: '23:59:59'}"
                            placeholder="选择时间范围">
                        </el-time-picker>
                    </div>
                </div>
                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">平谷电价</span>
                    <el-input class="inputStyle" v-model="saveData.pingguPrice" placeholder="请输入" type="text"></el-input>
                </div>

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">低谷时间</span>
                    <div class="inputStyle" style="display: flex">
                        <el-time-picker
                            v-model="saveData.lowstartTimeSlot[0]"
                            :picker-options="{minTime:'00:00:00',maxTime: '23:59:59'}"
                            placeholder="选择时间范围">
                        </el-time-picker>
                        <div style="margin: 0px 15px">-</div>
                        <el-time-picker
                            v-model="saveData.lowstartTimeSlot[1]"
                            :picker-options="{minTime:'00:00:00',maxTime: '23:59:59'}"
                            placeholder="选择时间范围">
                        </el-time-picker>
                    </div>
                </div>
                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">低谷电价</span>
                    <el-input class="inputStyle" v-model="saveData.lowPrice" placeholder="请输入" type="text"></el-input>
                </div>


                <!-- <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">传统灯具节能</span>
                    <el-input class="inputStyle" v-model="saveData.energySaving" placeholder="请输入" type="text"></el-input>
                </div>

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">削峰填谷储能灯具节能</span>
                    <el-input class="inputStyle" v-model="saveData.energyConservation" placeholder="请输入" type="text"></el-input>
                </div>

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">传统灯具月节能</span>
                    <el-input class="inputStyle" v-model="saveData.energySavingMonthly" placeholder="请输入" type="text"></el-input>
                </div>

                <div class="saveDataItem">
                    <span style="width: 200px;text-align: right;margin-right: 10px;">削峰填谷储能灯具月节能</span>
                    <el-input class="inputStyle" v-model="saveData.energyConservationMonthly" placeholder="请输入" type="text"></el-input>
                </div> -->


                <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;">
                    <div style="border-radius: 10px;background-color: #0c8697;padding: 10px 20px;margin-right: 20px;color: #fff;" @click="btnOk" >确定</div>
                    <div style="border-radius: 10px;background-color: #c4c4d6;padding: 10px 20px;color: #000;" @click="cancellation" >取消</div>
                </div>

            </div>
        </div>


    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
// import PaginationCom from '../../components/map/PaginationCom.vue';
import drawMixin from "../../utils/drawMixin";

import { streetLightMapGroupListAPI, streetLightMapDetectionListAPI } from "@/api";


export default {
    mixins: [drawMixin],
    components: {
        // PaginationCom
    },
    data() {
        return {
            currentPage: 1,
            totalPages: 9, // 假设总页数是 100
            pageSize: 2, // 每页显示 5 个页码
            showStartDots: false,
            showMiddleDots: true,
            showEndDots: false,

            project_id: '',   // 项目id
            grouping_id: '', // 分组id
            grouping_Index: 0, // 分组索引

            lang: 'zh',
            hideOnSinglePage: false,
            total: 90,
            pagination: {
                pageSize: 10,
                p: 1
            },
            map: null,
            listData: [],
            batteryData: null,

            minVoltage: 0, // 最小电压
            maxVoltage: 0, // 最大电压
            differentialPressure: 0, // 压差
            Battery: 0, // 剩余电量
            batEcele_month: 0,
            totalEcele_month: 0,
            photovoltaic: 0,

            saveElectricity: 0,
            costSavingsType: false, // 计算电费弹窗
            saveData: {
                peakstartTimeSlot: ['', ''],   // 高峰时间段
                peakendTime: '',   // 高峰时间
                peakPrice: '',     // 高峰电价

                lowstartTimeSlot: ['', ''],   // 低谷时间段
                lowendTime: '',   // 低谷时间
                lowPrice: '',     // 低谷电价

                pinggustartTimeSlot: ['', ''],   // 平谷时间段
                pingguendTime: '',   // 平谷时间
                pingguPrice: '',     // 平谷电价

                lightingFixtures: '', // 
                // lightPower: '', // 储能路灯功率
                power: '',     // 标称功率
                energySaving: '',     // 传统灯具节能
                energyConservation: '', // 削峰填谷储能灯具节能
                energySavingMonthly: '',   // 传统灯具月节能
                energyConservationMonthly: '',  // 削峰填谷储能灯具月节能
            },
        }
    },
    mounted() {
        console.log(this.$route.query);
        let query = this.$route.query; // { key1: value1, key2: value2 }
        this.project_id = query.p

        this.grouList()

        this.lang = localStorage.getItem('lang') || 'zh'
        this.totalPages = Math.ceil(this.listData.length / 10)


    },
    methods: {


        // 分组列表
        async grouList() {
            const res = await streetLightMapGroupListAPI({ project_id: this.project_id });
            console.log(res);
            res.data.forEach((it) => {
                it['chilType'] = false
            })
            this.listData = res.data

            this.batEcele_month = res.batEcele_month
            this.totalEcele_month = res.totalEcele_month
            this.photovoltaic = res.photovoltaic
            
            this.detectionList(this.listData[0].id)
        },
        // 设备列表
        async detectionList(id) {
            const res = await streetLightMapDetectionListAPI({ grouping_id: id });
            this.listData[this.grouping_Index].child = res

            this.listData[0].chilType = true
            console.log(this.listData);
            this.drawMap()
            // console.log(this.listData[this.grouping_Index].child[0]);
        },

        // 切换
        chilTypeChang(index) {
            this.grouping_Index = index
            // console.log(index);
            // console.log(this.listData[this.grouping_Index]);
            if (this.listData[this.grouping_Index].chilType) {
                this.listData[this.grouping_Index].chilType = false
            } else {
                if (this.listData[this.grouping_Index].child.length == 0) {
                    // console.log(index);
                    this.detectionList(this.listData[this.grouping_Index].id)
                } else {
                    this.listData[this.grouping_Index].chilType = true
                }
            }
        },


        // 绘画地图
        drawMap() {
            let that = this

            AMapLoader.load({
                key: '57872fb2616bf3a00786dfa47e2e2d56', // 替换为你的高德地图API Key
                version: '2.0',
                plugins: ['AMap.Geolocation'],
            }).then((AMap) => {

                // this.listData[this.grouping_Index].child = [
                //     {
                //         glong: '120.356235',
                //         glat: '30.219253',
                //         name: '设备01',
                //         deviceName: '89455152236552'
                //     },
                //     {
                //         glong: '120.356288',
                //         glat: '30.218954',
                //         name: '设备02',
                //         deviceName: '89455152236553'
                //     },
                //     {
                //         glong: '120.356149',
                //         glat: '30.218711',
                //         name: '设备03',
                //         deviceName: '89455152236554'
                //     },
                //     {
                //         glong: '120.356166',
                //         glat: '30.218384',
                //         name: '设备04',
                //         deviceName: '89455152236554'
                //     },
                //     {
                //         glong: '120.356071',
                //         glat: '30.218249',
                //         name: '设备05',
                //         deviceName: '89455152236555'
                //     },
                //     {
                //         glong: '120.356104',
                //         glat: '30.218076',
                //         name: '设备06',
                //         deviceName: '89455152236556'
                //     },
                //     {
                //         glong: '120.355912',
                //         glat: '30.217703',
                //         name: '设备07',
                //         deviceName: '89455152236557'
                //     },
                //     {
                //         glong: '120.356004',
                //         glat: '30.217511',
                //         name: '设备08',
                //         deviceName: '89455152236558'
                //     },
                // ]
                
              
                // 添加点位
                for (var i = 0; i < this.listData[this.grouping_Index].child.length; i++) {
                    let item = this.listData[this.grouping_Index].child[i]
                    // console.log(item, 'glongglongglong');
                    let jing = item.glong
                    let wei = item.glat

                    if (i == 0) {
                        // 创建地图实例      
                        this.map = new AMap.Map("mapContainer", {
                            mapStyle: "amap://styles/darkblue", //设置地图的显示样式
                            // 设置地图容器id
                            viewMode: "2D", // 是否为3D地图模式
                            zoom: 17, // 初始化地图级别
                            center: [jing, wei], // 初始化地图中心点位置
                        });
                    }

                    var markerContent = `<div name='${i}'>
                    <div style="width: 11rem;height: 2.3rem;padding: 3px;border-radius: 8px;background-color: #fff;">
                        <p style="font-size: 12px;">${item.name}</p>
                        <p style="font-size: 9px;">ID: ${item.deviceName}</p>
                    </div>
                    <img src="https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png" style="width: 20px;height: 30px;" alt="">
                </div>`
                    var marker = new AMap.Marker({
                        position: ([jing, wei]),
                        // 将 html 传给 content
                        content: markerContent,
                        // 以 icon 的 [center bottom] 为原点
                        offset: new AMap.Pixel(-13, -30),
                        extData: { item }, // 写入你的item对象,必须要加{}
                    });
                    marker.on('click', (e) => {
                        let markerItem = e.target.getExtData().item; // 获取item , 这个getExtData()是AMap.Marker的扩展方法
                        console.log(markerItem.name);
                        // console.log(e);
                        that.seek(markerItem.name)
                    });

                    this.map.add([marker]);

                }



            });
        },







        // 剩余电量
        BatteryFun() {
            if (!this.batteryData[`bat_${1}_soc`]) return 0
            let arr = []
            for (var i = 1; i <= 8; i++) {
                if (this.batteryData[`bat_${i}_soc`]) {
                    arr.push(this.batteryData[`bat_${i}_soc`] * 1)
                }
            }
            arr.sort((a, b) => a - b); // 升序
            // 移除最大值和最小值
            arr.shift(); // 移除最小值
            arr.pop();   // 移除最大值
            // 计算剩余元素的平均值
            console.log('剩余电量', arr)
            const sum = arr.reduce((total, num) => total + num, 0);
            console.log('剩余电量', sum)
            this.Battery = (sum / arr.length).toFixed(0)
            console.log(this.Battery)
        },
        // 查找最大值和最小值
        seekMaxMin() {
            if (!this.batteryData[`bat_${1}_vol`]) {
                this.minVoltage = 0
                this.maxVoltage = 0
                this.differentialPressure = 0
                return
            }

            let arr = []
            for (var i = 1; i <= 8; i++) {
                if (this.batteryData[`bat_${i}_vol`]) {
                    this.batteryData[`bat_${i}_vol`] = (this.batteryData[`bat_${i}_vol`] / 1000).toFixed(3)
                    arr.push(this.batteryData[`bat_${i}_vol`])
                }
            }
            arr.sort((a, b) => a - b); // 升序
            console.log(arr)
            this.minVoltage = arr[0]
            this.maxVoltage = arr[arr.length - 1]
            this.differentialPressure = (this.maxVoltage - this.minVoltage).toFixed(3)
            console.log(this.minVoltage, this.maxVoltage)
        },




        // 返回
        back() {
            this.$router.back();
        },
        changePage(pager) { // 分页器回调
            this.pagination = pager
            console.log(pager);

            // this.$emit('change', pager)
        },

        goToPage(page) {
            if (page < 1 || page > this.totalPages || page === this.currentPage) return;
            this.currentPage = page;
            console.log(this.currentPage);

            // this.deviceList()
        },
        next(type) {
            if (type == 'up') {
                if (this.currentPage > 1) {
                    this.currentPage--
                } else {
                    return
                }
            } else {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++
                } else {
                    return
                }
            }

            console.log(this.currentPage);
            // this.deviceList()
        },


        seek(item) {
            console.log(item);

            this.listData[this.grouping_Index].child.forEach(element => {
                if (element.name == item) {
                    console.log(element);
                    this.setCenter(element)
                }
            });
        },

        setCenter(coordinate) {
            console.log(coordinate);
            if(this.batteryData && coordinate.id == this.batteryData.id) {
                return
            }

            let jing = coordinate.glong
            let wei = coordinate.glat

            this.batteryData = coordinate

            this.BatteryFun()
            this.seekMaxMin()

            // this.map.setCenter([jing, wei]); //简写 设置地图中心点
            this.map.setCenter([jing, wei], false, 500)
            this.map.setZoom(20, false, 800)
        },



        visiblePages() {
            const start = Math.max(1, this.currentPage - Math.floor(this.pageSize / 2));
            const end = Math.min(this.totalPages, start + this.pageSize - 1);

            this.showStartDots = start > 1;
            this.showMiddleDots = end < this.totalPages && (start > 2 || end < this.totalPages - 1);
            this.showEndDots = end < this.totalPages - 1;

            return Array.from({ length: end - start + 1 }, (_, i) => i + start);
        },

        // 二氧化碳
        reduction() {
            if(!this.photovoltaic) return 0
            let num = this.photovoltaic.slice(0, -2);
            // console.log(num * 0.404);
            return Math.ceil(num * 0.404)
        },
        // 等效植树
        planting() {
            if(!this.photovoltaic) return 0
            let num = this.photovoltaic.slice(0, -2) * 0.404 / 5023
            // console.log(num);
            return Math.ceil(num)
        },

        btnOk() {
            if(this.saveData.peakstartTimeSlot.length != 2) {
                return
            }
            if(this.saveData.lowstartTimeSlot.length != 2) {
                return
            }
            if(this.saveData.pinggustartTimeSlot.length != 2) {
                return
            }
            
            if(!this.saveData.peakPrice) {
                return
            }
            if(!this.saveData.lowPrice) {
                return
            }
            if(!this.saveData.pingguPrice) {
                return
            }
            if(!this.saveData.lightingFixtures) {
                return
            }
            // if(!this.saveData.lightPower) {
            //     return
            // }
            
            let lightPower = this.batteryData.dischargePower / 1000
            let lightingFixtures = this.saveData.lightingFixtures / 1000

            // // 高峰时间
            this.saveData.peakendTime = this.getHoursBetweenTwoTimestamps(new Date(this.saveData.peakstartTimeSlot[0]).getTime(), new Date(this.saveData.peakstartTimeSlot[1]).getTime()).toFixed(2)
            // 低峰时间
            this.saveData.lowendTime = this.getHoursBetweenTwoTimestamps(new Date(this.saveData.lowstartTimeSlot[0]).getTime(), new Date(this.saveData.lowstartTimeSlot[1]).getTime())
            // 平谷时间
            this.saveData.pingguendTime = this.getHoursBetweenTwoTimestamps(new Date(this.saveData.pinggustartTimeSlot[0]).getTime(), new Date(this.saveData.pinggustartTimeSlot[1]).getTime())

            // 储能灯具节能
            let num1 = (lightPower * this.saveData.lowendTime * this.saveData.lowPrice)

            // 传统灯具节能
            let num2 = (lightingFixtures * this.saveData.peakendTime * this.saveData.peakPrice) + (lightingFixtures * this.saveData.lowendTime * this.saveData.lowPrice) + (lightingFixtures * this.saveData.pingguendTime * this.saveData.pingguPrice)
            
            // console.log(num2, num1);
            this.saveElectricity = (num2 - num1).toFixed(2)
            // console.log(this.saveElectricity);
            this.cancellation()
        },
        // 取消
        cancellation() {
            this.costSavingsType = false, // 计算电费弹窗
            this.saveData = {
                peakstartTimeSlot: ['', ''],   // 高峰时间段
                peakendTime: '',   // 高峰时间
                peakPrice: '',     // 高峰电价

                lowstartTimeSlot: ['', ''],   // 低谷时间段
                lowendTime: '',   // 低谷时间
                lowPrice: '',     // 低谷电价

                pinggustartTimeSlot: ['', ''],   // 平谷时间段
                pingguendTime: '',   // 平谷时间
                pingguPrice: '',     // 平谷电价

                lightPower: '', // 储能路灯功率
                power: '',     // 标称功率
                energySaving: '',     // 传统灯具节能
                energyConservation: '', // 削峰填谷储能灯具节能
                energySavingMonthly: '',   // 传统灯具月节能
                energyConservationMonthly: '',  // 削峰填谷储能灯具月节能
            }
        },


        // 将时间戳转换为毫秒
        getHoursBetweenTwoTimestamps(timestamp1, timestamp2) {

            var diff = Math.abs(timestamp1 - timestamp2); // 计算差值，确保是正数

            if(timestamp1 > timestamp2) {
                diff = 86399000 - diff
            }

            // 将毫秒转换为小时
            return Math.ceil(diff / (1000 * 60 * 60))
        }

    }
};
</script>
<style lang='scss' scoped>
// 头部
.head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 11vh;
    background: url("../../assets/BMS/Group 70@2x.png") no-repeat;
    background-size: cover;
    background-position: center center;
}

.listBg {
    position: fixed;
    top: 11vh;
    left: 50px;
    border-radius: 10px;
    height: 78vh;
    width: 16%;
    background: rgba(#000000, .8);
    // overflow: hidden;

}

.listBox {
    border-radius: 10px;
    height: 100%;
    width: 100%;
    // background: url("../../assets/BMS/Group@2x.png") no-repeat;
    // background-size: contain;
    // background-position: center 0px;
    box-shadow: inset 0px 1px 30px 20px rgba(10, 125, 140, 0.3);
}

.listData {
    padding: 20px;
    height: 100%;
    overflow-y: scroll;
}

.listData::-webkit-scrollbar {
    width: 8px;
}

.listData::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(12, 128, 144, .5);
}

.listData::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(255, 255, 255, 0.2);
}


.leftItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: url("../../assets/BMS/Group 73@2x.png") no-repeat;
    background-size: contain;
    background-position: center 0px;
    width: 99%;
    height: 50px;
    margin-bottom: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #FFFFFF;
}

.switchBtn {
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);

    .pagination {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;

        .pageNumber {
            background: url("../../assets/BMS/Rectangle 36@2x.png") no-repeat;
            background-size: contain;
            background-position: center 0px;
            padding: 5px 10px;
            color: #fff;
            font-size: 14px;
        }

        .page-item_dots {
            background: url("../../assets/BMS/Rectangle 36@2x.png") no-repeat;
            background-size: contain;
            background-position: center 0px;
            padding: 5px 10px;
            color: #fff;
            font-size: 14px;
        }

        .page-link {
            color: #66ffff;
        }
    }

    .next {
        background: url("../../assets/BMS/Rectangle 38@2x.png") no-repeat;
        background-size: contain;
        background-position: center 0px;
        padding: 4px 10px;
        color: #fff;
        font-size: 14px;
    }
}

.rgba1 {
    background: rgba(#000000, .8);
}
.rgba0 {
    background: rgba(#000000, .0);
}

.rigthBox {
    position: fixed;
    top: 14vh;
    right: 50px;
    // background-color: #fff;
    border-radius: 10px;
    height: 75vh;
    width: 19vw;
}

.right {
    width: 100%;
    height: 100%;
    background: url("../../assets/BMS/Group@2x.png") no-repeat;
    background-size: contain;
    background-position: center 0px;
    padding-top: 10px;

    .rightTop {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;


        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 150px;
            height: 70px;
            margin-bottom: 14px;
            background: url("../../assets/BMS/Group@2x(1).png") no-repeat;
            background-size: contain;
            background-position: center 0px;
            color: #66FFFF;
            font-size: 22px;
        }
    }

    .rightButton {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0px 30px;

        .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 14px;

            .battery {
                width: 100px;
                height: 50px;
                padding: 8px;
                padding-right: 20px;
                margin: 0px 10px;
                background: url("../../assets/BMS/battery.png") no-repeat;
                background-size: contain;
                background-position: center 0px;
            }

            .batteryNum {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                .tet {
                    font-weight: 500;
                    font-size: 24px;
                    color: #02BDBC;
                    z-index: 999;
                }

                .win {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 40%;
                    height: 100%;
                    background-color: #66ffff;
                }
            }
        }
    }
}

.conItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // width: 190px;
    height: 70px;
    margin-right: 14px;
    box-shadow: inset 0px 1px 30px 20px rgba(10, 125, 140, 0.3);
    background: rgba(#000000, .8);
    color: #66FFFF;
    font-size: 22px;
    padding: 10px;
    border: 1px solid #0de6de;
}

.pup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#000000, .6);
}
.saveData {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 70vh;
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;

    .saveDataItem {
        display: flex;
        align-items: center;

        .inputStyle {
            flex: 1;
        }
    }
}

.el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner {
    width: 100% !important;
}
</style>